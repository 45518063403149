@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  font-family: Poppins;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header-container{
  height: 100px;
}

.header-dropdwon{
  background: center;
  outline: none;
  font-size: 12px;
  text-align: center;
}
.header-dropdwon option{
  background: #535454;
  color: white;
}
.navArea{
  font-size: 12px;
  margin: 0 !important;


}
.navArea li{
  margin: 0 !important;
}
.searchContainer{
  border: 1px solid white;
  /* border-radius: 16px ; */

}
.search-icon{
  margin: 0px !important;
  padding: 1px 16px;
  background: transparent;
  /* border-radius: 0px 16px 16px 0px; */
  font-size: larger;
  cursor: pointer;
}
.headerSecondContainer{
  display: flex;
  flex-direction: row;
}
.searchBar{
  outline: none;
  /* border-radius: 16px 0px 0px 16px; */
  background: transparent;
  max-width: 200px;
}

.subBtn{
  background: #535454;
  border-radius: 10px;
}

.thankyou-subBtn{
  background: #535454;

}
.tax-para{
  display: block;
  font-size: 12px;
}
.formLables label{
  width: 35%;
}
.placeholder-option {
  opacity: 0.5;
}
/* .header, .navBar{
  background: #535454;
} */

.container h1{
  font-family: Playfair Display;
  font-weight: 600;
  font-size: 30px;
}
h2{
  /* font-family: Playfair Display; */
  font-weight: 600;
  font-size: 30px;
}
.lastPageContainer h1{
  font-family: Playfair Display;
  font-weight: 600;
  font-size: 25px;
}
.font-semibold {
  font-weight: 600;
  font-size: 20px;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  color:#535454;
  ;
}
#prefix{
  border-right: 1px solid #535454;
}
.grid {
  display: grid;
  gap: 1rem;
}

.md\\:grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}

.hover\\:underline:hover {
  text-decoration: underline;
}

.mr-2 {
  margin-right: 0.5rem;
}

.space-x-4 > :not(:last-child) {
  /* margin-right: 1rem; */
}

.text-blue-600 { color: #2563eb; }
.text-blue-500 { color: #3b82f6; }
.text-blue-400 { color: #60a5fa; }
.text-pink-600 { color: #ec4899; }
.text-red-600 { color: #dc2626; }

.col{
  margin: 0 auto;
}
.link_text{
  font-size: small;
  /* padding-bottom: 10px; */
}
.footer-bottom{
  /* color: #535454; */
  font-size: 12px;
}

/* src/styles/custom.css */

.header-dropdown {
  background-color: #535454; 
  color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  outline: none;
  cursor: pointer;
}
.border-t {
  border-top: 1px solid #285b71;
}
.header-dropdown:hover {
  background-color: #535454;
}

.businessInfo{
  background: #f5f5f5;
  font-size: 12px;
  line-height: 18px;
}
.bazaarPolicy{
  color: #535454;
  font-size: 12px;
  line-height: 18px;
}
.thankyouQuestion{
  color: #535454;

}

.footer-container{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 12%;
  padding-right: 12%;
}
.footer-left-container{
 width:48%;
 display: flex;
 justify-content: left;
}
.footer-right-container{
  width:33%;
  display: flex;
  justify-content: right;
  gap: 30px;
}

.header-right-container{
  flex-direction: row;
}
.tax-radio-container{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.custom-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox {
  width: 14px;
  height: 14px;
  border: 1px solid #535454;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox.checked {
  background-color: #535454;
}

.custom-checkbox.checked::after {
  content: '';
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


@media (max-width: 600px) {
  .header-container{
    height: 150px;
  }
  .tax-radio-container{
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  .headerSecondContainer {
    flex-direction: column;
  }
  .header-right-container{
    flex-direction: column;
  }

  .footer-left-container{
   width:100%;
   display: flex;
   justify-content: center;
   text-align: center;
  }
  /* .header-logo-container{
    height: 130px;
  } */
  .footer-right-container{
    width:100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .europe-footer-container {
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .europe-footer-left-container,
  .europe-footer-right-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px; /* Optional: Adds space between the two sections */
  }

  .europe-footer-right-container {
    gap: 0px;
  }
  .india-footer-container {
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .india-footer-left-container,
  .india-footer-right-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px; /* Optional: Adds space between the two sections */
  }

  .india-footer-right-container {
    gap: 0px;
  }
}


/* FOR EUROPE ==>> */


.europe-subBtn{
  background: #4B5666;
  border-radius: 10px;
}
.europe-valid-subBtn{
  background: white;
  border-radius: 10px;
  border: 1px solid #4B5666;
  color: #4B5666;
  font-weight: 600;
}
.europe-thankyou-subBtn{
  background: #548091;

}

.europe-container {
  max-width: 1080px;
  margin: 0 auto;
  color:#4B5666;
}
.europe-container h1{
  font-family: Playfair Display;
  font-weight: 600;
  font-size: 30px;
}
.europe-prefix{
  border-right: 1px solid #4B5666;
}

.europe-bazaarPolicy{
  color: #4B5666;
  font-size: 12px;
  line-height: 18px;
}
.europe-thankyouQuestion{
  color: #4B5666;
}

.europe-custom-checkbox {
  width: 14px;
  height: 14px;
  border: 1px solid #4B5666;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.europe-custom-checkbox.checked {
  background-color: #4B5666;
}
.europe-custom-checkbox.checked::after {
  content: '';
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.europeHeaderTextColour{
  color: #548091;
}
.europeFooterTextColour{
  /* color: #548091; */
  color: #4b5666;
}
.europeHeaderButtonBackgroundColour{
  background-color: #548091;
}
.europe-footer-container{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 12%;
  padding-right: 12%;
}
.europe-footer-left-container{
 width:48%;
 display: flex;
 justify-content: left;
}
.europe-footer-right-container{
  width:33%;
  display: flex;
  justify-content: right;
  gap: 30px;
}

/* FOR INDIA ==>> */

.india-businessInfo{
  background: #E1EBEF;
  font-size: 12px;
  line-height: 18px;
}

.india-subBtn{
  background: #176B87;
  border-radius: 10px;
}
.india-valid-subBtn{
  background: white;
  border-radius: 10px;
  border: 1px solid #176B87;
  color: #176B87;
  font-weight: 600;
}
.india-thankyou-subBtn{
  background: #548091;

}

.india-container {
  max-width: 1080px;
  margin: 0 auto;
  color:#176B87;
}
.india-container h1{
  font-family: Playfair Display;
  font-weight: 600;
  font-size: 30px;
}
.india-prefix{
  border-right: 1px solid #176B87;
}

.india-bazaarPolicy{
  color: #176B87;
  font-size: 12px;
  line-height: 18px;
}
.india-thankyouQuestion{
  color: #176B87;
}

.india-custom-checkbox {
  width: 14px;
  height: 14px;
  border: 1px solid #176B87;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.india-custom-checkbox.checked {
  background-color: #176B87;
}
.india-custom-checkbox.checked::after {
  content: '';
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.indiaHeaderTextColour{
  color: #548091;
}
.indiaFooterTextColour{
  /* color: #548091; */
  color: #176B87;
}
.indiaHeaderButtonBackgroundColour{
  background-color: #548091;
}
.india-footer-container{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 12%;
  padding-right: 12%;
}
.india-footer-left-container{
 width:48%;
 display: flex;
 justify-content: left;
}
.india-footer-right-container{
  width:33%;
  display: flex;
  justify-content: right;
  gap: 30px;
}

