.pdf-viewer-container {
    width: 68.5vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.pdf-viewer {
    width: 78%;
    height: 100vh;
}



/* Media query for phones (max-width: 768px) */
@media screen and (max-width: 768px) {
    .pdf-viewer-container {
        width: 100%;
        height: 50vh;

    }

    .pdf-viewer {
        width: 100%;
        height: 50vh;

    }
}
